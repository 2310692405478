<template>
  <a-form-model
  class='reg'
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item
      ref="name"
      label="用户名"
      prop="name"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的用户名" v-model="form.name">
        <a-icon slot="prefix" type="user"
      /></a-input>
    </a-form-model-item>
    <a-form-model-item
      ref="realName"
      label="真实姓名"
      prop="realName"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input
        placeholder="请输入您的真实姓名"
        v-model="form.realName"
        id="realName"
      >
        <a-icon slot="prefix" type="user"
      /></a-input>
    </a-form-model-item>
    <a-form-model-item
      ref="email"
      label="邮箱"
      prop="email"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的邮箱" v-model="form.email">
        <a-icon slot="prefix" type="folder"
      /></a-input>
    </a-form-model-item>
    <a-form-model-item
      ref="school"
      label="学校"
      prop="school"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的学校" v-model="form.school">
        <a-icon slot="prefix" type="bank"
      /></a-input>
    </a-form-model-item>

    <a-form-model-item
      ref="sex"
      label="性别"
      prop="sex"
      :labelCol="{ span: 24, offset: 0 }"
    >
      <a-select v-model="form.sex" placeholder="请选择您的性别">
        <a-select-option value="男"> 男 </a-select-option>
        <a-select-option value="女"> 女 </a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- <a-form-model-item
      ref="type"
      label="证件类型"
      prop="type"
      :labelCol="{ span: 24, offset: 0 }"
    >
      <a-select v-model="form.type" placeholder="请选择您的证件类型">
        <a-select-option value="居民身份证"> 居民身份证 </a-select-option>
        <a-select-option value="港澳台身份证"> 港澳台身份证 </a-select-option>
        <a-select-option value="护照"> 护照 </a-select-option>
        <a-select-option value="回乡证"> 回乡证 </a-select-option>
        <a-select-option value="其他"> 其他 </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      ref="card"
      label="证件号"
      prop="card"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的证件号" v-model="form.card">
        <a-icon slot="prefix" type="credit-card"
      /></a-input>
    </a-form-model-item> -->
    <a-form-model-item

      label="生日"
      ref="date"
      prop="date"
      :labelCol="{ span: 24, offset: 0 }"
    >
      <a-date-picker
        v-model="form.date"
        type="date"
        placeholder="请选择您的生日"
        style="width: 100%"
      />
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="onSubmitReg" style="width: 100%">
        下一步
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      visiblereg: false,
      codeBtn: true,
      codeText: "发送验证码",
      countdown: 60,
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      cardS: false,
      form: {
        // name: "",
        // pass: "",
        // code: "",
        // phone: "",
        name: "",
        email: "",
        school: "",

        sex: undefined,
        type: undefined,
        card: "",
        date: "",
        realName: "",
      },
      rules: {
        school: [
        // {
        //     required: true,
        //     message: "请输入您的学校",
        //     trigger: "blur",
        //   },
      
        ],
        sex: [
          { required: true, message: "请选择您的性别", trigger: "change" },
          {
            validator: this.checkSex,
            trigger: "change",
          },
        ],
        type: [
          { required: true, message: "请选择您的证件类型", trigger: "change" },
          {
            validator: this.checkType,
            trigger: "change",
          },
        ],
        card: [
          { required: true, message: "请输入您的证件号", trigger: "blur" },
          {
            validator: this.checkCard,
            trigger: "blur",
          },
        ],
        date: [
          { required: true, message: "请选择您的生日", trigger: "change" },
        ],
        name: [
          {
            required: true,
            message: "请输入您的用户名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "请输入2-20个字符",
            trigger: "blur",
          },
             {
            validator: this.userName,
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入您的真实姓名",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "请输入1-20个字符",
            trigger: "blur",
          },
          {
            validator: this.checkName,
            trigger: "blur",
          },
        ],
        email: [
        // {
        //     required: true,
        //     message: "请输入您的邮箱",
        //     trigger: "blur",
        //   },
          {
            validator: this.checkEmail,
            trigger: "blur",
          },
        ],
        // phone: [
        //   {
        //     required: true,
        //     message: "请输入您的手机号",
        //     trigger: "blur",
        //   },
        //   {
        //     validator: this.checkGrade,
        //     trigger: "blur",
        //   },
        // ],
        // code: [
        //   {
        //     required: true,
        //     message: "请输入您的验证码",
        //     trigger: "blur",
        //   },
        //   {
        //     min: 1,
        //     max: 6,
        //     message: "请输入1-6个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    openNotification(description) {
      this.$notification.open({
        message: "证件类型为身份证时",
        description: description,
        icon: <a-icon type="close-circle" style="color:red" />,
      });
    },
    onSubmitReg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 如果 证件类型是身份证要做校验了
          // 真实姓名/性别和证件号、证件号是否是身份证号
          if (this.form.type == "居民身份证") {
            // 居民身份证== 中文
            let structRight = RegExp(
              /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/
            ).test(this.form.realName);
            // 非居民身份证==中文+英文
            // let structLeft = RegExp(/^[A-Za-z]*(\s[A-Za-z]*)*$/).test(
            //   this.form.realName
            // );
            // 校验证件号
            let cardReg = RegExp(
              /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/
            ).test(this.form.card);
            // 证件类型不为空
            if (!structRight) {
              this.openNotification("真实姓名只能输入中文");
              // 弹出真实姓名不符合
              return;
            }
            if (!cardReg) {
              this.openNotification("证件号不符合身份证号规则");
              // 弹出身份证号不符合规则
              return;
            }
            if (
              (this.form.card.substr(16, 1) % 2 == 1 &&
                this.form.sex == "女") ||
              (this.form.card.substr(16, 1) % 2 == 0 && this.form.sex == "男")
            ) {
              // 弹出 性别和身份证不相符合
              this.openNotification("证件号和性别不一致");
              return;
            }
          }
           window.localStorage.setItem("anjing-useranjing-nameNew", this.form.name);
           window.localStorage.setItem("anjing-emailNew", this.form.email);
           window.localStorage.setItem("anjing-schoolNew", this.form.school);
           window.localStorage.setItem("anjing-nameNew", this.form.realName);
           window.localStorage.setItem("anjing-cardtypeNew", this.form.type);
           window.localStorage.setItem("anjing-cardidNew", this.form.card);
           window.localStorage.setItem("anjing-sexNew", this.form.sex);
           window.localStorage.setItem("anjing-birthdayNew",  moment( this.form.date).format('YYYY-MM-DD'));
          this.$emit("submitFun", 2, 0, 1);
        } else {
          console.log("error submit!!");
          this.current = 0;
          return false;
        }
      });
    },

    checkEmail(rule, value, callback) {
      console.log(value);
      if (value != "") {
        let structRight = RegExp(
          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        ).test(value);
        if (!structRight) {
          callback(new Error("请填写正确的邮箱"));
          // this.codeBtn = true;
          // this.phoneCode = true;
        } else {
          let data = {
            op: "checkemail",
            email: this.form.email,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                callback();
              } else {
                callback(new Error(response.data.msg));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        callback();
      }
    },
    userName(rule, value, callback) {
        let data = {
            op: "checkeusername",
            username: this.form.name,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                callback();
              } else {
                callback(new Error(response.data.msg));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
    },
    checkName(rule, value, callback) {
      // 居民身份证== 中文
      let structRight = RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/).test(
        value
      );
      // 非居民身份证==中文+英文
      let structLeft = RegExp(/^[A-Za-z]*(\s[A-Za-z]*)*$/).test(value);
      if (!(structRight || structLeft) && this.form.type == undefined) {
        callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
      } else {
        callback();
      }

      /***
 * 
 *   // 证件类型不为空
      else if (
        this.form.type != undefined &&
        this.form.type == "居民身份证" &&
        !structRight
      ) {
        callback(new Error("证件类型为居民身份证时,考生姓名必须输入中文"));
      } else if (
        this.form.type != undefined &&
        this.form.type != "居民身份证" &&
        !(structRight || structLeft)
      ) {
        callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
      }
 * 
 */
    },
    checkSex(rule, value, callback) {
      // 如果证件类型不为空且是居民身份证且证件号不为空的情况下校验 性别和证件号是否一致
      if (
        this.form.type != undefined &&
        this.form.type == "居民身份证" &&
        this.card != ""
      ) {
        if (
          (this.form.card.substr(16, 1) % 2 == 1 && this.form.sex == "女") ||
          (this.form.card.substr(16, 1) % 2 == 0 && this.form.sex == "男")
        ) {
          callback(new Error("身份证号与性别不一致"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 证件类型
    checkType(rule, value, callback) {
      if (this.form.type != undefined && this.form.type == "居民身份证") {
        this.cardS = false;
        callback();
      } else {
        this.cardS = true;
        callback();
      }
    },
    //证件号
    checkCard(rule, value, callback) {
      //checkcardid
      let data = {
        op: "checkcardid",
        cardid: this.form.card,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            callback();
          } else {
            callback(new Error(response.data.msg));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //   let data = {
    //     op: "checkeusername",
    //     username: this.form.name,
    //   };
    //   let _this = this;
    //   this.$ajax
    //     .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
    //     .then(function (response) {
    //       console.log(response);
    //       if (response.data.value == "ok") {
    //         callback();
    //       } else {
    //         callback(new Error(response.data.msg));
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    // // 性别：如果证件类型是身份证 就要校验 与证件号
  },
};
</script>
<style scoped>
/deep/.add .ant-col-17 {
  width: 100%;
}
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}
</style>