<template>
  <div id="box">
    <!-- <headTop></headTop> -->
    <!-- <start></start> -->
   <router-view></router-view>     
    
  </div>
</template>

<script>
// import headTop from '../components/header.vue'
// import start from './start.vue'
export default {
  name: "box",
  // components: {
  //  headTop,
  // //  start
  // },
  data() {
    return {
  
    };
  },
  created: function () {

  },
  methods: {
    toIndex(){
        this.$router.push('/index');
        // window.localStorage.setItem('anjing-to','index')
    }
  },
};
</script>

<style>
.start{
    margin-top:100px;
}

</style>
