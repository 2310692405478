import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import loginRegPass from '../views/login-reg-pass.vue'
import loginUrl from '../views/loginUrl.vue'
import regUrl from '../views/regUrl.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/start',
    // component: () => import(/* webpackChunkName: "about" */ '../views/start.vue'),
    children: [
      {
        path: '/index',
        name: 'index',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),

      }, {
        path: '/list',
        name: 'list',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/list.vue')
      },
      {
        path: '/pre',
        name: 'pre',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pre.vue')
      },
      {
        path: '/ter',
        name: 'ter',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ter.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
      },
      {
        path: '/detailVideo',
        name: 'detailVideo',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/detailVideo.vue')
      },
    
      {
        path: '/bank',
        name: 'bank',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "bank" */ '../views/bank.vue')
      },
      {
        path: '/classes',
        name: 'classes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "classes" */ '../views/classes.vue')
      },
      {
        path: '/grade',
        name: 'grade',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "grade" */ '../views/grade.vue')
      },
   //list-scratch
   {
    path: '/list-scratch',
    name: 'list-scratch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list-scratch" */ '../views/list-scratch.vue')
  },
  //list-scratch-video
  {
    path: '/list-scratch-video',
    name: 'list-scratch-video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "list-scratch-video" */ '../views/list-scratch-video.vue')
  },
  {
    path: '/class',
    name: 'class',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "class" */ '../views/class.vue')
  },
    ]
  },

  {
    path: '/start',
    name: 'start',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/start.vue'),
  },

  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/reg',
    name: 'reg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/reg.vue')
  },



  {
    path: '/pass',
    name: 'pass',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pass.vue')
  },
    // 邀请码登录和注册
    {
      path: '/login-reg-pass',
      name: 'login-reg-pass',
      component: loginRegPass
    },
    {
      path: '/loginUrl',
      name: 'loginUrl',
      component: loginUrl
    },
    {
      path: '/regUrl',
      name: 'regUrl',
      component: regUrl
    },
]

const router = new VueRouter({
  routes
})

export default router
