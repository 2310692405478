<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
 
   <router-view/>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
a{
  color:#69a4fa !important;
  text-decoration: none !important;
}
.common-width{
  width:1240px !important;
  margin:0 auto !important

}
.ant-message .anticon{
  top:-2px;
}
@media (max-width:1200px) {
.common-width{
  width:100% !important;
}

}


</style>

<script>


export default {
  name: "app",
  components: {
    
  },
  data() {
    return {
 
    };
  }

};
</script>
