import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios' 
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Qs from 'qs' 
Vue.config.productionTip = false
Vue.prototype.$ajax = axios
Vue.prototype.Qs = Qs
Vue.use(ElementUI).use(Antd)
//https://coderk12.t.educg.com/
Vue.prototype.url= ''



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
