<template>
  <a-tabs default-active-key="1" @change="callback" align-center>
    <a-tab-pane key="1" tab="密码登录">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" class="pass">
        <a-form-model-item
          ref="name"
          label="用户名"
          prop="name"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您的账号" v-model="form.name">
            <a-icon slot="prefix" type="user"
          /></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="用户名">
             
            <a-input v-model="form.name" placeholder="请输入您的账号">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item> -->

        <a-form-model-item
          label="密码"
          prop="pass"
          ref="pass"
          :labelCol="{ span: 4, offset: 0 }"
          labelAlign="left"
        >
          <a-input-password placeholder="请输入您的密码" v-model="form.pass">
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <p style="overflow: hidden" :class="!pass ? 'passUrl' : ''">
          <span @click="passTO" class="for codeQ">忘记密码？</span>
        </p>

        <a-form-model-item>
          <a-button type="primary" @click="onSubmit" style="width: 100%">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-tab-pane>
    <a-tab-pane key="2" tab="验证码登录" force-render>
      <a-form-model
        ref="ruleFormReg"
        :model="form"
        :rules="rules"
        style="margin-top: 10px"
      >
        <a-form-model-item
          ref="phone"
          label="手机号（国际/港澳台号码格式为：国家区号+手机号码）"
          prop="phone"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
        >
          <a-input placeholder="请输入您手机号" v-model="form.phone">
            <a-icon slot="prefix" type="tablet"
          /></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="用户名">
             
            <a-input v-model="form.name" placeholder="请输入您的账号">
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-model-item> -->

        <a-form-model-item
          ref="code"
          prop="code"
          label="验证码"
          :labelCol="{ span: 24, offset: 0 }"
          labelAlign="left"
          style="width: 77%; display: table-cell"
        >
          <a-input placeholder="请输入您的验证码" v-model="form.code">
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          style="width: 25%; display: table-cell; padding-top: 27px"
        >
          <a-button
            type="primary"
            :disabled="codeBtn"
            @click="codeFun"
            style="width: 96px; padding: 0; margin-left: 5px"
          >
            {{ codeText }}
          </a-button>

          <a-drawer
            title="温馨提示"
            placement="top"
            :closable="false"
            :visible="visible"
            :after-visible-change="afterVisibleChange"
            @close="onClose"
            width="400"
          >
            <lrp />
          </a-drawer>
        </a-form-model-item>
        <p style="overflow: hidden">
          <span class="codeQ" @click="showDrawer">没收到验证码？</span>
        </p>
        <a-form-model-item>
          <a-button type="primary" @click="onSubmitReg" style="width: 100%">
            登录
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import lrp from "@/components/lrp.vue";
export default {
  components: {
    lrp,
  },
  props: {
    pass: Boolean,
  },
  data() {
    return {
      visible: false,
      btnSize: { span: 23, offset: 2 },
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      other: "",
      codeBtn: true,
      phoneCode: true,
      codeText: "发送验证码",
      countdown: 60,
      form: {
        name: "",
        pass: "",
        code: "",
        phone: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入您的账号",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入您的验证码",
            trigger: "blur",
          },
          {
            min: 1,
            max: 6,
            message: "请输入1-6个字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入您的手机号",
            trigger: "blur",
          },
          {
            validator: this.checkGrade,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if ( window.localStorage.getItem("anjing-regName")) {
      this.form.name =  window.localStorage.getItem("anjing-regName");
      this.form.pass =  window.localStorage.getItem("anjing-regPass");
      this.form.phone =  window.localStorage.getItem("anjing-regPhone");
    }
  },
  methods: {
    passTO() {
      this.$emit("loginFun", true, "no", 2);
    },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    codeFun() {
      let data = {
        op: "getcode",
        phone: this.form.phone,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.settime();
          } else {
            _this.$notification.open({
              message: "验证码",
              duration: 4,
              description: response.data.msg,
              onClick: () => {
                console.log("验证码接口");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    settime() {
      //发送验证码倒计时
      if (this.countdown == 0) {
        this.countdown = 60;
        this.codeText = "发送验证码";

        this.codeBtn = this.phoneCode;
        return;
      } else {
        this.codeText = this.countdown;
        this.codeBtn = true;

        this.countdown--;
      }
      setTimeout(() => {
        this.settime();
      }, 1000);
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
        this.codeBtn = true;
        this.phoneCode = true;
      } else {
        let data = {
          op: "check",
          phone: this.form.phone,
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/new_login.do", _this.Qs.stringify(data))
          .then(function (response) {
            console.log(response);
            if (response.data.value == "ok") {
              callback();
              _this.codeBtn = false;
              _this.phoneCode = false;
            } else {
              callback(new Error(response.data.msg));
              _this.codeBtn = true;
              _this.phoneCode = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 分享链接的登陆舰
          // let joincode = null;
          // if ( window.localStorage.getItem("anjing-joincode")) {
          //   joincode =  window.localStorage.getItem("anjing-joincode");
          // } else {
          //   joincode = "";
          // }

          // alert("submit!");
          let data = {
            op: "login_psw",
            username: this.form.name,
            password: this.form.pass,
            // joincode: joincode,
            group:window.localStorage.getItem("k12-group"),
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_login.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: '登录成功',
                  onClick: () => {
                    console.log("登录");
                  },
                });
                // _this.$emit("loginFun", false, "ok");
                 window.localStorage.setItem(
                  "anjing-tokenName",
                  response.data.data.name
                );
                window.localStorage.setItem(
                  "anjing-token-group",
                  response.data.data.group
                );
                _this.$router.push({path:window.localStorage.getItem('k12-type')})
                console.log(_this.$route.query)
              } else {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmitReg() {
      // 如果pass==false说明是邀请
      this.$refs.ruleFormReg.validate((valid) => {
        if (valid) {
          // 分享链接的登陆舰
          // let joincode = null;
          // if ( window.localStorage.getItem("anjing-joincode")) {
          //   joincode =  window.localStorage.getItem("anjing-joincode");
          // } else {
          //   joincode = "";
          // }
          let data = {
            op: "login_code",
            phone: this.form.phone,
            code: this.form.code,
            // joincode: joincode,
            group:window.localStorage.getItem("k12-group"),
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_login.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
                  window.localStorage.setItem(
                  "anjing-tokenName",
                  response.data.data.name
                );
                window.localStorage.setItem(
                  "anjing-token-group",
                  response.data.data.group
                );
                if (
                   window.localStorage.getItem("anjing-joincode") &&
                   window.localStorage.getItem("anjing-joincode") != ""
                ) {
                  _this.$router.push({ path: "/" });
                } else {
                  _this.$emit("loginFun", false, "ok");
                }

               
              } else {
                _this.$notification.open({
                  message: "登录",
                  duration: 2,
                  description: response.data.msg,
                  onClick: () => {
                    console.log("登录");
                  },
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // resetForm() {
    //   this.$refs.ruleForm.resetFields();
    // },
    onFieldBlur() {
      console.log("ZHELI ");
    },
    callback() {},
  },
};
</script>


<style scoped>
.codeQ {
  color: #69a4fa;
  cursor: pointer;
  float: right;
}
.ms {
  font-size: 14px;
}
.ant-form-item-label {
  display: block !important;
  text-align: left;
}
.passUrl {
  visibility: hidden;
}

/deep/ .ant-tabs-tabpane {
  margin-top: 20px;
}
.pass /deep/.ant-form-item:nth-child(1) {
  margin-bottom: 13px;
}
.pass /deep/.ant-form-item:nth-child(2) {
  margin-bottom: 0;
}
</style>